<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <button class="p-link" id="btn_back" @click="backToRapport">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="my-2">
          <h3>{{ t('expiredAP') }}</h3>
          <h3>{{ titleTotalNumber }}</h3>
        </div>
      </template>

    </Toolbar>
    <div class="col-12">
      <div class="card">
        <div class="my-2" v-if="countStatusImport>0">
          <h6>{{ t('importActionPlan') }}</h6>
        </div>

        <DataTable :value="actionPlanList" :totalRecords="totalItemsAP"
                   @page="onPageActionPlan($event.page +1)" dataKey="id" :paginator="true" :rows="30"
                   :rowHover="true"
                   class="p-datatable-gridlines table-filter-texte"
                   v-model:filters="filters1" filterDisplay="menu" :loading="loadingAP"
                   responsiveLayout="scroll"
                   :globalFilterFields="['title','start_date','end_date','revised_end_date','user_pilot','user_in_charge','rate_progress','priority_flag']"
                   @filter="filterDataTable($event)"
                   @update:filters="handleFilterDateApplied"
        >
          <template #loading>
            Loading data. Please wait.
          </template>
          <template #header>
            <div class="table-header-container">


              <Button label="Export" icon="pi pi-download" class="add-text  mr-2" @click="exportAP"
                      :disabled="btnExport" v-if="!['3'].includes(role_user_site)"/>

            </div>
          </template>

          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
          <Column :header="t('entitled')" style="width:10rem" filterField="title"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p>'">
                <p>
                  {{ truncate(data.title) }}
                </p>
              </div>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>
          <Column :header="t('startDate')" style="min-width:8rem" filterField="start_date" dataType="date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.start_date) }}</span>
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                        selectionMode="range"/>
            </template>
          </Column>
          <Column :header="t('endDate')" style="min-width:7rem" filterField="end_date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.end_date) }}</span>
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                        selectionMode="range"/>
            </template>
          </Column>
          <Column :header="t('revisedEndDate')" style="min-width:8rem" filterField="revised_end_date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.revised_end_date) }}</span>
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                        selectionMode="range"/>
            </template>
          </Column>

          <Column field="name" :header="t('responsibleAP')" style="width:10rem" filterField="user_in_charge"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <p>{{ data.user_in_charge_firstname }} {{ data.user_in_charge_lastname }}</p>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('searchByName')"/>
            </template>
          </Column>

          <Column :header="t('progressRate')" filterField="rate_progress" dataType="numeric"
                  style="min-width:5rem"
                  class="progressBarValue" :showFilterMatchModes="false">

            <template #body="slotProps">
              <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                             v-if="slotProps.data.rate_progress<=50"
                             :showValue="true" style="height:20px" class="valuered"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                             v-if="slotProps.data.rate_progress>50 && slotProps.data.rate_progress<70"
                             :showValue="true"
                             style="height:20px" class="valueyellow"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                             v-if="slotProps.data.rate_progress>=70 "
                             :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
            </template>

            <template #filter={filterModel}>
              <Slider v-model="filterModel.value" range class="m-3"></Slider>
              <div class="flex align-items-center justify-content-between px-2">
                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>
              </div>
            </template>
          </Column>
          <Column :header="t('priority')" filterField="priority_flag" dataType="numeric" style="min-width:3rem"
                  :showFilterMatchModes="false">
            <template #body="slotProps">

              <img :src="'demo/images/flags/flag-'+slotProps?.data.priority_flag+'.png'" :alt="slotProps.data.image"
                   width="20" v-if="slotProps?.data.priority_flag"/>

            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="optionsFlag" placeholder="Any"
                        class="p-column-filter"
                        :showClear="true">
                <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">
                     <img :src="'demo/images/flags/flag-'+slotProps.value+'.png'" width="20"/>
                  </span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'customer-badge status-' + slotProps.option">
                     <img :src="'demo/images/flags/flag-'+slotProps.option+'.png'" width="20"/>
                  </span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column style="min-width:4rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                      @click="modalEditPA(slotProps.data)"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
              <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                      @click="deletePA(slotProps.data)"
                      v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                      v-if=" !['1','3'].includes(role_user_site)"/>
            </template>
          </Column>
        </DataTable>
        <Dialog v-model:visible="PADialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
          <div class="formgrid grid">
            <div class="field-control col">

              <label for="titre">{{ t('entitled') }}<span>*</span> </label>
              <InputText id="name" v-model="planAction.title" autofocus
                         :class="{'p-invalid': submittedPA && !planAction.title}"/>
              <small class="p-invalid" v-if="submittedPA && !planAction.title">{{
                  t('titleRequired')
                }}</small>

            </div>
            <div class="field-control col">
              <label for="titre">{{ t('reference') }} </label>
              <InputText id="name" v-model="planAction.reference" autofocus
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="statut">{{ t('subDomain') }}</label>
              <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                          placeholder="Select Item"
                          @node-select="selectNode"/>

            </div>
            <div class="field-control col">

              <label for="Priorite">{{ t('priority') }} </label>
              <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name" required="true"
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">

              <label for="Priorite">{{ t('apPilot') }} </label>
              <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"/>
            </div>

            <div class="field-control col">
              <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
              <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                        required="true"
                        :class="{'p-invalid': submittedPA && !responsibleAP}"/>
              <small class="p-invalid" v-if="submittedPA && !responsibleAP">{{
                  t('responsibleAPRequired')
                }}</small>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="Priorite">{{ t('typeProgresion') }} </label>
              <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                        required="true"/>
            </div>

            <div class="field-control col">
              <label for="Priorite">{{ t('rateProgress') }} </label>
              <Dropdown v-model="rateProgress" :options="listRateProgression" optionLabel="label"/>
            </div>

          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('startDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                        dateFormat="dd/mm/yy"
                        :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
              <small class="p-invalid" v-if="submittedPA && !start_date">{{
                  t('startDateRequired')
                }}</small>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('endDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                        dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                        @update:modelValue="changeEndDate($event)"></Calendar>
              <small class="p-invalid" v-if="submittedPA && !end_date">{{ t('endDateRequired') }}</small>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                        dateFormat="dd/mm/yy"
                        :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
              <small class="p-invalid"
                     v-if="submittedPA && !revised_end_date">{{ t('revisedEndDateRequired') }}</small>
            </div>

          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="planAction.reminder_fixed"/>
                <label for="confidentiel">{{ t('fixedReminder') }}</label>
              </div>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
              <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus

              />
            </div>

          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="planAction.reminder_periodic"/>
                <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
              </div>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('periodicReminderTime') }} </label>
              <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus

              />
            </div>

          </div>
          <div class="formgrid grid">

            <div class="field-control col">
              <label for="description">{{ t('divers') }} </label>
              <Textarea id="description" v-model="planAction.others" required="true" rows="5" cols="20"/>
            </div>
          </div>
          <template #footer>

            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogPA"/>


            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="savePA()" v-if="!['1','3'].includes(role_user_site)"/>

          </template>
        </Dialog>
        <Dialog v-model:visible="PAEditDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" :header="titleAP">
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>{{ t('compliances') }}</span>
              </template>
              <DataTable :value="evaluationsAP" :totalRecords="totalItemsCompliancePA"
                         dataKey="id" :paginator="true" :rows="30"
                         :rowHover="true"
                         class="p-datatable-gridlines table-filter-texte"
                         :loading="loadingCompliancesPA"
                         responsiveLayout="scroll">
                <template #loading>
                  Loading data. Please wait.
                </template>
                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #header>
                  <div class="table-header-container">
                    <Button :label="t('addACompliance')" icon="pi pi-plus"
                            class="add-text p-button-success mr-2"
                            @click="openModalCompliance()"/>
                  </div>
                </template>
                <Column field="name" :header="t('titleOfText')" filterField="title"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong>'+t('titleOfText')+ ':</strong>'+ data?.compliance?.section?.document?.title+'</p>'">
                      <p v-html="data?.compliance?.section?.document?.title"></p>
                    </div>
                  </template>
                </Column>

                <Column field="name" :header="t('titleOfCompliance')" filterField="title"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong>'+t('titleOfCompliance')+ ':</strong>'+ data?.compliance?.section?.title+'</p>'">
                      <p v-html="data?.compliance?.section?.title"></p>
                    </div>
                  </template>
                </Column>
                <Column field="name" :header="t('text')" filterField="title"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data?.compliance?.title+'</p>'">
                      <p v-html="data?.compliance?.title"></p>
                    </div>
                  </template>
                </Column>
                <Column style="min-width:4rem">
                  <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                            @click="dialogEvaluation(slotProps.data)"
                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                    <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                            @click="deleteCompliance(slotProps.data)"
                            v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                            v-if="!['1','3','2'].includes(role_user_site)"/>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('tasks') }}</span>
              </template>

              <DataTable :value="actionPlanDetails.action_plan_tasks" :lazy="true"
                         :totalRecords="totalItemsTasks"
                         dataKey="id" :paginator="true" :rows="30"
                         :rowHover="true"
                         class="p-datatable-gridlines table-filter-texte"
                         :loading="loadingTasks"
                         responsiveLayout="scroll">
                <template #loading>
                  Loading data. Please wait.
                </template>

                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #header>
                  <div class="table-header-container">
                    <Button :label="t('addingTasks')" icon="pi pi-plus"
                            class="add-text p-button-success mr-2"
                            @click="openModalTasks()"/>
                  </div>
                </template>

                <Column field="name" :header="t('wording')" style="width:10rem">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p>'">
                      <p>{{ data.title }}</p>
                    </div>
                  </template>
                </Column>

                <Column field="name" :header="t('responsibleTasks')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ data?.user_in_charge?.firstname }} {{
                        data?.user_in_charge?.lastname
                      }}</p>
                  </template>
                </Column>

                <Column field="name" :header="t('startDate')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ formatDate(data.start_date) }}</p>
                  </template>
                </Column>

                <Column field="name" :header="t('endDate')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ formatDate(data.end_date) }}</p>
                  </template>
                </Column>
                <Column :header="t('progressRate')" filterField="rate_progress" dataType="numeric"
                        style="min-width:7rem"
                        class="progressBarValue">
                  <template #body="slotProps">
                    <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                                   v-if="slotProps.data.rate_progress<=50"
                                   :showValue="true" style="height:20px"
                                   class="valuered"></AxProgressBar>
                    <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                                   v-if="slotProps.data.rate_progress>50 && slotProps.data.rate_progress<70"
                                   :showValue="true"
                                   style="height:20px" class="valueyellow"></AxProgressBar>
                    <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                                   v-if="slotProps.data.rate_progress>=70 "
                                   :showValue="true" style="height:20px"
                                   class="valuegreen"></AxProgressBar>
                  </template>
                </Column>

                <Column style="min-width:4rem">
                  <template #body="slotProps">

                    <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                            @click="openModalTasks(slotProps.data)"
                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"
                    />
                    <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                            @click="deleteTask(slotProps.data)"
                            v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                            v-if="!['1','3'].includes(role_user_site)"/>
                  </template>
                </Column>

              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Identification</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('title') }}<span>*</span> </label>
                  <InputText id="name" v-model="planAction.title" autofocus
                             :class="{'p-invalid': submittedPA && !planAction.title}"/>
                  <small class="p-invalid"
                         v-if="submittedPA && !planAction.title">{{ t('titleRequired') }}</small>
                </div>
                <div class="field-control col">
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('reference') }} </label>
                  <InputText id="name" v-model="planAction.reference" autofocus
                  />
                </div>

                <div class="field-control col">
                  <label for="statut">{{ t('subDomain') }}</label>
                  <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                              placeholder="Select Item"
                              @node-select="selectNode"/>

                </div>

              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('divers') }} </label>
                  <Textarea id="description" v-model="planAction.others"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('priority') }} </label>
                  <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('process') }} </label>
                  <InputText id="name" v-model="planAction.process"
                  />
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('risksopportunities') }} </label>
                  <InputText id="name" v-model="planAction.risks_opportunities"
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Pilotage</span>
              </template>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('startDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                            dateFormat="dd/mm/yy"
                            :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !start_date">{{
                      t('startDateRequired')
                    }}</small>
                </div>

                <div class="field-control col">
                  <label for="titre">{{ t('endDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                            dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                            @update:modelValue="changeEndDate($event)"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !end_date">{{
                      t('endDateRequired')
                    }}</small>
                </div>

                <div class="field-control col">
                  <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                            dateFormat="dd/mm/yy"
                            :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !revised_end_date">{{
                      t('revisedEndDateRequired')
                    }}</small>
                </div>

              </div>

              <div class="formgrid grid">
                <div class="field-control col">

                  <label for="Priorite">{{ t('apPilot') }} </label>
                  <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"/>

                </div>

                <div class="field-control col">

                  <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
                  <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                            required="true"
                            :class="{'p-invalid': submittedPA && !responsibleAP}"/>
                  <small class="p-invalid"
                         v-if="submittedPA && !responsibleAP">{{ t('responsibleAPRequired') }}</small>

                </div>

              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('typeProgresion') }} </label>
                  <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                            required="true"/>
                </div>

                <div class="field-control col">
                  <label for="Priorite">{{ t('rateProgress') }} </label>
                  <Dropdown v-model="rateProgress" :options="listRateProgression"
                            optionLabel="label"/>
                </div>

              </div>

              <div class="formgrid grid">
                <div class="field-control col">
                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true"
                              v-model="planAction.reminder_fixed"/>
                    <label for="confidentiel">{{ t('fixedReminder') }}</label>
                  </div>
                </div>

                <div class="field-control col">
                  <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
                  <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus/>
                </div>

              </div>

              <div class="formgrid grid">
                <div class="field-control col">
                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true"
                              v-model="planAction.reminder_periodic"/>
                    <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
                  </div>
                </div>

                <div class="field-control col">
                  <label for="titre">{{ t('periodicReminderTime') }} </label>
                  <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus/>
                </div>

              </div>

            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('measureEffectiveness') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('verificationType') }} </label>
                  <Textarea id="description" v-model="planAction.verification_type"/>
                </div>
                <div class="field-control col">
                  <label>{{ t('comment') }} </label>
                  <Textarea id="description" v-model="planAction.comment"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('verificationDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="verification_date"
                            dateFormat="dd/mm/yy">
                  </Calendar>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('responsibleVerification') }} </label>
                  <Dropdown v-model="verifier_user" :options="listUsers" optionLabel="user_name"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('resources') }}</span>
              </template>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('financialRsources') }} </label>
                  <Textarea id="description" v-model="planAction.financial_resources"/>
                </div>

                <div class="field-control col">
                  <label>{{ t('operationalResource') }} </label>
                  <Textarea id="description" v-model="planAction.operational_resource"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('technologicalResources') }} </label>
                  <Textarea id="description" v-model="planAction.technological_resources"/>
                </div>

                <div class="field-control col">
                  <label>{{ t('humanResources') }} </label>
                  <Textarea id="description" v-model="planAction.humain_resources"/>
                </div>
              </div>

            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('comments') }}</span>
              </template>

              <DataTable :value="actionPlanDetails.action_plan_comments" :lazy="true"
                         :totalRecords="totalItemsTasks"
                         dataKey="id" :paginator="true" :rows="30"
                         :rowHover="true"
                         class="p-datatable-gridlines table-filter-texte"
                         :loading="loadingComments"
                         responsiveLayout="scroll">
                <template #loading>
                  Loading data. Please wait.
                </template>

                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #header>
                  <div class="table-header-container">
                    <Button :label="t('addingComments')" icon="pi pi-plus"
                            class="add-text p-button-success mr-2"
                            @click="openModalComments()"/>
                  </div>
                </template>

                <Column field="name" :header="t('comment')" style="width:10rem">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong>'+t('comment')+ ':</strong>'+ data.comment+'</p>'">
                      <p>{{ data.comment }}</p>
                    </div>
                  </template>
                </Column>

                <Column field="name" :header="t('creationDate')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ formatDate(data.created_at) }}</p>
                  </template>
                </Column>

                <Column field="name" :header="t('createdBy')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ data?.created_by?.firstname }}{{ data?.created_by?.lastname }}</p>
                  </template>
                </Column>

                <Column field="name" :header="t('updatedAt')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ formatDate(data.updated_at) }}</p>
                  </template>
                </Column>

                <Column field="name" :header="t('updatedBy')" style="width:10rem">
                  <template #body="{data}">
                    <p>{{ data?.updated_by?.firstname }}{{ data?.updated_by?.lastname }}</p>
                  </template>
                </Column>

                <Column style="min-width:4rem">
                  <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                            @click="openModalComments(slotProps.data)"
                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"
                    />
                    <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                            @click="deleteComment(slotProps.data)"
                            v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                            v-if="!['1','3'].includes(role_user_site)"/>
                  </template>
                </Column>

              </DataTable>
            </TabPanel>
          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogEditPA"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    :disabled="submittedPA" @click="savePAEdit()" v-if="!['1','3'].includes(role_user_site)"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="tasksDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('title') }}<span>*</span> </label>
              <InputText id="name" v-model="task.title" autofocus
                         :class="{'p-invalid': submittedTasks && !task.title}"/>
              <small class="p-invalid" v-if="submittedTasks && !task.title">{{
                  t('titleRequired')
                }}</small>
            </div>
            <div class="field-control col">
              <label for="Priorite">{{ t('responsibleTasks') }}</label>
              <Dropdown v-model="responsibleTask" :options="listUsers" optionLabel="user_name"/>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('startDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date_task"
                        dateFormat="dd/mm/yy"></Calendar>
              <small class="p-invalid" v-if="submittedTasks && !start_date_task">{{
                  t('startDateRequired')
                }}</small>
            </div>
            <div class="field-control col">
              <label for="titre">{{ t('endDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date_task"
                        dateFormat="dd/mm/yy"></Calendar>
              <small class="p-invalid" v-if="submittedTasks && !end_date_task">{{
                  t('endDateRequired')
                }}</small>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('thematic') }}</label>
              <InputText id="name" v-model="task.thematic" autofocus/>
            </div>
            <div class="field-control col">
              <label for="Priorite">{{ t('rateProgress') }} </label>
              <Dropdown v-model="rateProgress_task" :options="listRateProgression" optionLabel="label"/>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="description">Description </label>
              <Textarea id="description" v-model="task.description" rows="5" cols="20"/>
            </div>
            <div class="field-control col">
              <label for="description">{{ t('comment') }} </label>
              <Textarea id="description" v-model="task.comment" rows="5" cols="20"/>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true" v-model="task.reminder_fixed"/>
                <label for="confidentiel">{{ t('fixedReminder') }}</label>
              </div>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
              <InputNumber id="name" v-model="task.reminder_before_deadline" autofocus/>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="task.reminder_periodic"/>
                <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
              </div>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('periodicReminderTime') }} </label>
              <InputNumber id="name" v-model="task.periodic_reminder_time" autofocus/>
            </div>
          </div>


          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogTask"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    :disabled="btntaskSubmit" @click="saveTask()" v-if="!['1','3'].includes(role_user_site)"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="commentsDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('comment') }}<span>*</span> </label>
              <textarea id="name" v-model="commentAP.comment" autofocus
                        :class="{'p-invalid': submittedComments && !commentAP.comment}" rows="5"
                        cols="20"/>
              <small class="p-invalid"
                     v-if="submittedComments && !commentAP.comment">{{ t('commentRequired') }}</small>
            </div>

          </div>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogComment"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    :disabled="btnCommentSubmit" @click="saveComment()" v-if="!['1','3'].includes(role_user_site)"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="complianceDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
          <DataTable :value="documents" v-model:expandedRows="expandedRowsDoc" :lazy="true"
                     :totalRecords="totalItemsDoc"
                     @page="onPageDoc($event)" dataKey="id" :paginator="true" :rows="nbItems"
                     :rowsPerPageOptions="[5,10,30,50]"
                     :rowHover="true"
                     class="p-datatable-gridlines table-filter-texte"
                     @rowExpand="onRowExpandDoc($event.data.id,1)"
                     filterDisplay="menu" :loading="loadingDoc"
                     responsiveLayout="scroll"
                     :globalFilterFields="['titleDescription','theme','domain','subDomain']"
                     @filter="filterDataTableCompliance($event)"
                     v-model:filters="filtersCompliances" :filters="filtersCompliances">
            <template #loading>
              Loading data. Please wait.
            </template>
            <template #header>
              <div class="table-header-container">
                <Button type="button" icon="pi pi-minus" :label="t('cutThroughAll')"
                        @click="collapseAll"
                        class="add-text p-button-help mr-2"/>
              </div>
            </template>
            <Column :expander="true" headerStyle="width: 1rem" style="width: 1rem;padding: 2px !important;"
                    class="toggler_chevron"/>
            <template #empty>
              {{ t('NoDataToDisplay') }}
            </template>
            <Column header="" class="td_flag">
              <template #body="slotProps">
                <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="20"
                     v-if="slotProps?.data?.live_status?.color==='black'"/>
                <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="20"
                     v-if="slotProps?.data?.live_status?.color==='red'"/>
                <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="20"
                     v-if="slotProps?.data?.live_status?.color==='yellow'"/>
                <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="20"
                     v-if="slotProps?.data?.live_status?.color==='green'"/>
              </template>
            </Column>
            <Column field="name" :header="t('text')" style="width:10rem" filterField="titleDescription"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <div class="tooltip"
                     v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p><p><strong>Description :</strong> '+
                     replaceAllColorsWithWhite(data.description)+'</p><p><strong>'+t('fieldApplication') +': </strong>'+data.application_fields+'</p>'">
                  <p>{{ data.title }}</p>
                  <p v-html="data.description" v-if="data.description !== 'null'" class="text-light"></p>
                </div>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('search')"/>
              </template>
            </Column>
            <Column :header="t('theme')" style="min-width:8rem" filterField="theme"
                    :showFilterMatchModes="false">
              <template #body="{data}">
              <span style="vertical-align: middle" v-if="data.theme_id"
                    class="image-text">{{ data.theme_id }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesTheme" placeholder="Any"
                          class="p-column-filter"
                          :showClear="true">
                  <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value"
                        v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                                            slotProps.option.label
                                          }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column :header="t('domain')" filterField="domain" :showFilterMatchModes="false"
                    style="min-width:8rem">
              <template #body="{data}">
              <span style="vertical-align: middle" class="image-text" v-if="data.domain_id">{{
                  data.domain_id
                }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder="Any"
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value"
                        v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                                            slotProps.option.label
                                          }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column :header="t('subDomain')" filterField="subDomain" :showFilterMatchModes="false"
                    style="min-width:8rem">
              <template #body="{data}">
               <span style=" vertical-align: middle" class="image-text" v-if="data.sub_domain_id">
                     {{ data.sub_domain_id }}
               </span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder="Any"
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value"
                        v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                                            slotProps.option.label
                                          }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div class="orders-subtable eval_level_1">
                <DataTable :value="slotProps.data.sections" responsiveLayout="scroll"
                           :lazy="true"
                           v-model:expandedRows="expandedRowsSec"
                           @rowExpand="onRowExpandSection($event.data, 1)"
                           :loading="loadingSection[slotProps.data.id]"
                           :paginator="true" :rows="10" :row-class="rowClass"
                           :totalRecords="totalItemsSect[slotProps.data.id]"
                           @page="onPageSec(slotProps.data.id, $event.page+1)">
                  <Column :expander="true" headerStyle="width: 3rem">
                  </Column>
                  <template #empty>
                    {{ t('NoDataToDisplay') }}
                  </template>
                  <Column field="text" style="width:50rem">
                    <template #body="slotProp">
                      {{ truncate(slotProp.data.title) }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="orders-subtable eval_level_2">
                      <h5>{{ slotProps.data.name }}</h5>
                      <DataTable :value="slotProps.data.compliances" responsiveLayout="scroll"
                                 :lazy="true"
                                 :totalRecords="totalItemsCompliance[slotProps.data.id]"
                                 @page="onpageCompliance(slotProps.data, $event)"
                                 :loading="loadingCompliance[slotProps.data.id]" :paginator="true"
                                 :rows="10"
                                 v-model:selection="selectedCompliances[slotProps.data.id]">
                        <template #empty>
                          {{ t('NoDataToDisplay') }}
                        </template>
                        <Column selectionMode="multiple"
                                style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>
                        <Column field="text" :header="t('titleOfCompliance')"
                                style="width:10rem">
                          <template #body="slotProps">
                            <div class="tooltip"
                                 v-tooltip.right="'<p><strong>'+t('novalliaAnnotation')+ ': </strong>'+ slotProps.data.title+
                                     '</p><p><strong>'+ t('customerAnnotation')+ ' :</strong> '+ slotProps.data.evaluations[0].annotation+' </p><p><strong>'+ t('comment')+' :</strong>'+ slotProps.data.evaluations[0].comment+
                                     '</p><p><strong>'+ t('valuationDate') + ' :</strong>'+  formatDate(slotProps.data.evaluations[0].evaluation_date)  +'</p>'">
                              <p>{{ truncateText(slotProps.data.titleSection) }}</p>
                            </div>
                          </template>
                        </Column>
                        <Column field="text" :header="t('textOfCompliance')"
                                style="width:50rem">
                          <template #body="slotProps">
                            <span v-html="slotProps.data.title"> </span>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </template>
          </DataTable>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogCompliance"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    :disabled="btnAPSubmit" @click="saveCompliances()" v-if="!['1','3'].includes(role_user_site)"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="evaluationDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle">
          <template #header>
            <span v-html="titleDialogEvaluation"></span>
          </template>
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>{{ t('evaluations') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('evaluation') }}<span>*</span> </label>
                  <Dropdown v-model="evaluationStatus" :options="valuesEvaluations" optionLabel="name"
                            required="true"
                            :class="{'p-invalid': submitted && !evaluationStatus}"
                            @change="chnageStatus()"/>
                  <small class="p-invalid"
                         v-if="submitted && !evaluationStatus">{{
                      t('statusEvaluationRequired')
                    }}</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('valuationDate') }} :</label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="evaluation_date"
                            dateFormat="dd/mm/yy"></Calendar>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col" v-if="!verifAdmin">
                  <label for="description">Annotation :</label>
                  <Textarea id="description" v-model="evaluation.annotation" required="true" rows="5"
                            cols="20"
                            disabled/>
                </div>
                <div class="field-control col" v-if="verifAdmin">
                  <label for="description">Annotation :</label>
                  <Textarea id="description" v-model="evaluation.annotation" required="true" rows="5"
                            cols="20"/>
                </div>
                <div class="field-control col">
                  <label for="description">{{ t('comment') }} :</label>
                  <Textarea id="description" v-model="evaluation.comment" required="true" rows="5"
                            cols="20"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('historic') }}</span>
              </template>
              <DataTable :value="historicEvaluation" :paginator="true" :rows="10" :lazy="true"
                         :totalRecords="totalItemsHistEval"
                         responsiveLayout="scroll" @page="onPageHistEval($event.page +1)"
                         class="p-datatable-gridlines table-filter-texte">

                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <Column field="evaluation" header="Evaluation">
                  <template #body="slotProps">
                    {{ slotProps.data.status }}
                  </template>
                </Column>
                <Column field="ValuedBy" :header="t('ValuedBy')">
                  <template #body="slotProps">
                    {{ slotProps.data.username }}
                  </template>
                </Column>
                <Column field="representative.name" :header="t('comment')">
                  <template #body="slotProps">
                    {{ slotProps.data.comment }}
                  </template>
                </Column>
                <Column field="representative.name" :header="t('valuationDate')">
                  <template #body="slotProps">
                    {{ formatDate(slotProps.data.evaluation_date) }}
                  </template>
                </Column>
                <Column field="representative.name" :header="t('updatedAt')">
                  <template #body="slotProps">
                    {{ formatDate(slotProps.data.updated_at) }}
                  </template>
                </Column>

              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('actionPlan') }}</span>
              </template>
              <DataTable :value="actionPlanListEval" :paginator="true" :rows="30" :lazy="true"
                         :totalRecords="totalItemsAPEval" :loading="loadingApEval"
                         responsiveLayout="scroll" @page="onPageActionPlanEval($event.page +1)"
                         class="p-datatable-gridlines table-filter-texte">
                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #header>
                  <div class="table-header-container" style="display:flex">
                    <Button :label="t('attachActionPlan')" icon="pi pi-paperclip"
                            class="p-button p-component add-text mr-2"
                            @click="modalAttachAP"/>
                    <Button :label="t('addActionPlan')" icon="pi pi-plus"
                            class="p-button p-component add-text mr-2"
                            @click="openModalAP(1)"/>
                  </div>
                </template>
                <Column field="ValuedBy" :header="t('title')">
                  <template #body="slotProps">
                    {{ slotProps.data.title }}
                  </template>
                </Column>
                <Column style="min-width:4rem">
                  <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                            @click="modalEditPAEval(slotProps.data)"
                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"
                    />
                    <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                            @click="deleteAPEval(slotProps.data)"
                            v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                            v-if="!['1','3'].includes(role_user_site)"/>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogEval"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    :disabled="submitted" @click="saveEvaluation" v-if="!['1','3','2'].includes(role_user_site)"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="PAEvalEditDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" :header="titleAP">
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>Identification</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('title') }}<span>*</span> </label>
                  <InputText id="name" v-model="planAction.title" autofocus
                             :class="{'p-invalid': submittedPA && !planAction.title}"/>
                  <small class="p-invalid"
                         v-if="submittedPA && !planAction.title">{{ t('titleRequired') }}</small>
                </div>
                <div class="field-control col">
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('reference') }} </label>
                  <InputText id="name" v-model="planAction.reference" autofocus/>
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('subDomain') }}</label>
                  <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                              placeholder="Select Item"
                              @node-select="selectNode"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('divers') }} </label>
                  <Textarea id="description" v-model="planAction.others"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('priority') }} </label>
                  <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('process') }} </label>
                  <InputText id="name" v-model="planAction.process"
                  />
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('risksopportunities') }} </label>
                  <InputText id="name" v-model="planAction.risks_opportunities"
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Pilotage</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('startDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                            dateFormat="dd/mm/yy"
                            :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !start_date">{{
                      t('startDateRequired')
                    }}</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('endDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                            dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                            @update:modelValue="changeEndDate($event)"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !end_date">{{
                      t('endDateRequired')
                    }}</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                            dateFormat="dd/mm/yy"
                            :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !revised_end_date">{{
                      t('revisedEndDateRequired')
                    }}</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('apPilot') }} </label>
                  <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
                  <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                            required="true"
                            :class="{'p-invalid': submittedPA && !responsibleAP}"/>
                  <small class="p-invalid"
                         v-if="submittedPA && !responsibleAP">{{ t('responsibleAPRequired') }}</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('typeProgresion') }} </label>
                  <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                            required="true"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('rateProgress') }} </label>
                  <Dropdown v-model="rateProgress" :options="listRateProgression"
                            optionLabel="label"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true"
                              v-model="planAction.reminder_fixed"/>
                    <label for="confidentiel">{{ t('fixedReminder') }}</label>
                  </div>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
                  <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true"
                              v-model="planAction.reminder_periodic"/>
                    <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
                  </div>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('periodicReminderTime') }} </label>
                  <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('measureEffectiveness') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('verificationType') }} </label>
                  <Textarea id="description" v-model="planAction.verification_type"/>
                </div>
                <div class="field-control col">
                  <label>{{ t('comment') }} </label>
                  <Textarea id="description" v-model="planAction.comment"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('verificationDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="verification_date"
                            dateFormat="dd/mm/yy">
                  </Calendar>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('responsibleVerification') }} </label>
                  <Dropdown v-model="verifier_user" :options="listUsers" optionLabel="user_name"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('resources') }}</span>
              </template>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('financialRsources') }} </label>
                  <Textarea id="description" v-model="planAction.financial_resources"/>
                </div>

                <div class="field-control col">
                  <label>{{ t('operationalResource') }} </label>
                  <Textarea id="description" v-model="planAction.operational_resource"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('technologicalResources') }} </label>
                  <Textarea id="description" v-model="planAction.technological_resources"/>
                </div>

                <div class="field-control col">
                  <label>{{ t('humanResources') }} </label>
                  <Textarea id="description" v-model="planAction.humain_resources"/>
                </div>
              </div>

            </TabPanel>
          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogEditPAEval"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="savePAEdit(1)" v-if="!['1','3'].includes(role_user_site)"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="attachPADialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" :header="t('attachActionPlan')">
          <DataTable :value="actionPlanListAttach" :lazy="true" :totalRecords="totalItemsAPAttach"
                     @page="onPageActionPlanAttach($event.page +1)" dataKey="id" :paginator="true" :rows="30"
                     :rowHover="true"
                     class="p-datatable-gridlines table-filter-texte"
                     v-model:filters="filters2" filterDisplay="menu" :loading="loadingAPAttach"
                     :filters="filters2"
                     responsiveLayout="scroll"
                     :globalFilterFields="['title','start_date','end_date','revised_end_date','user_pilot','user_in_charge','rate_progress','priority']"
                     @filter="filterDataTableAttach($event)"
                     v-model:selection="selectedAPAttach">
            <template #loading>
              Loading data. Please wait.
            </template>
            <template #header>
              <div class="table-header-container">
                <Button :label="t('addActionPlan')" icon="pi pi-plus"
                        class="add-text p-button-success mr-2"
                        @click="openModalAP()"/>
              </div>
            </template>
            <template #empty>
              {{ t('NoDataToDisplay') }}
            </template>
            <Column selectionMode="multiple"
                    style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>
            <Column field="name" :header="t('text')" style="width:10rem" filterField="title"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <div class="tooltip"
                     v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p>'">
                  <p>{{ data.title }}</p>

                </div>

              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('searchByName')"/>
              </template>
            </Column>
            <Column :header="t('startDate')" style="min-width:8rem" filterField="start_date"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.start_date) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('endDate')" style="min-width:8rem" filterField="end_date"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.end_date) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('revisedEndDate')" style="min-width:8rem" filterField="revised_end_date"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.revised_end_date) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column field="name" :header="t('apPilot')" style="width:10rem" filterField="user_pilot"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <p>{{ data?.user_pilot?.firstname }} {{ data?.user_pilot?.lastname }}</p>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('searchByName')"/>
              </template>
            </Column>
            <Column field="name" :header="t('responsibleAP')" style="width:10rem"
                    filterField="user_in_charge"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <p>{{ data.user_in_charge.firstname }} {{ data.user_in_charge.lastname }}</p>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('searchByName')"/>
              </template>
            </Column>
            <Column field="name" :header="t('tasks')" style="width:10rem">
              <template #body="{data}">
                {{ data?.action_plan_tasks?.length }}
              </template>
            </Column>
          </DataTable>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogAttachPA"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    :disabled="disableAttachPA" @click="saveAttachPA" v-if="!['1','3'].includes(role_user_site)"/>
          </template>
        </Dialog>


      </div>
    </div>
  </div>
  <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
  <Toast/>
</template>
<script>
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {FilterMatchMode} from "primevue/api";
import {computed} from "vue";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import AxProgressBar from "@/components/AxProgressBar";
import moment from "moment-timezone";

export default {
  components: {
    AxProgressBar
  },
  data() {
    return {
      valueProgress: 0,
      filters1: null,
      filters2: null,
      categoriesTheme: [],
      categoriesDomain: [],
      categoriesSubDomain: [],
      categoriesThemeDocument: [],
      categoriesDomainDocument: [],
      categoriesSubDomainDocument: [],
      textAdmin: '',
      categoryValueTheme: '',
      categoryValueDomain: '',
      categoryValueSubDomain: '',
      submitted: false,
      submittedPA: false,
      tabCategoriesTheme: [],
      tabCategoriesDomain: [],
      tabCategoriesSubDomain: [],
      verifAdmin: true,
      verifSuperAdmin: false,
      verifUser46: false,
      categoriesThemeConst: [],
      categoriesDomainConst: [],
      categoriesSubDomainConst: [],
      actionPlanList: [],
      actionPlanListAttach: [],
      actionPlanListAttachConst: [],
      nbPageActionPlan: 1,
      nbPageActionPlanAttach: 1,
      actionPlanListEval: [],
      totalItemsAPEval: 0,
      totalItemsAPAttach: 0,
      nbPageActionPlanEval: 1,
      PADialog: false,
      planAction: {},
      valueSubDomain: '',
      tabCategories: [],
      priority: '',
      valuesPriorities: [{id: 1, name: 'Mineure', value: '78'}, {id: 2, name: 'Moyenne', value: '79'}, {
        id: 3,
        name: 'Majeure',
        value: '80'
      }, {
        id: 0,
        name: 'Aucune',
        value: '228'
      },],
      start_date: '',
      end_date: '',
      revised_end_date: '',
      listUsers: [],
      responsibleAP: '',
      apPilot: '',
      listRateProgression: [
        {id: 1, value: 0, label: '0%',},
        {id: 2, value: 20, label: '20%'},
        {id: 3, value: 40, label: '40%'},
        {id: 4, value: 60, label: '60%'},
        {id: 5, value: 80, label: '80%'},
        {id: 6, value: 100, label: '100%'}],
      rateProgress: {id: 1, value: 0, label: '0%',},
      listTypeProgression: [
        {id: 1, value: 'Par le chargé du plan d\'actions'},
        {id: 2, value: 'Avancement des tâches'},
      ],
      typeProgress: {id: 1, value: 'Par le chargé du plan d\'actions'},
      totalItemsAP: 0,
      loadingAP: false,
      loadingAPAttach: false,
      titleFilter: '',
      startDateFilter: '',
      endDateFilter: '',
      revisedEndDateFilter: '',
      userPilotFilter: '',
      userInChargeFilter: '',
      priorityFilter: '',
      optionsFlag: ['red', 'green', 'yellow'],
      rateProgressFilter: '',
      PAEditDialog: false,
      actionPlanDetails: '',
      totalItemsCompliance: {},
      totalItemsCompliancePA: 0,
      tasksDialog: false,
      task: {},
      start_date_task: '',
      end_date_task: '',
      rateProgress_task: {id: 1, value: 0, label: '0%',},
      responsibleTask: '',
      idPA: '',
      submittedTasks: false,
      totalItemsTasks: 0,
      totalItemsComments: 0,
      loadingTasks: false,
      loadingComments: false,
      verifier_user: '',
      verification_date: '',
      commentAP: {},
      submittedComments: false,
      commentsDialog: false,
      complianceDialog: false,
      totalItemsDoc: 0,
      expandedRowsDoc: [],
      nbItems: 10,
      loadingDoc: true,
      documents: [],
      loadingSection: {},
      loadingCompliance: {},
      loadingCompliancesPA: false,
      expandedRowsSec: [],
      totalItemsSect: {},
      selectedCompliances: {},
      evaluationDialog: false,
      evaluation: {},
      valuesEvaluations: [],
      evaluationStatus: '',
      PAEvalEditDialog: false,
      historicEvaluation: [],
      totalItemsHistEval: 0,
      attachPADialog: false,
      selectedAPAttach: null,
      titleDialogEvaluation: '',
      titleAP: '',
      evaluationsAP: [],
      filtersCompliances: null,
      importAPDialog: false,
      fileImportPA: '',
      expandedRowsAP: [],
      listTasksAP: [],
      totalAPTasks: [],
      totalAPCompliances: [],
      listSiteByEntity: [],
      listSitesValid: false,
      btnExport: false,
      role_user_site: false,
      site_id_in_modal: null,
      titleTotalNumber: '',
      btnAPSubmit: false,
      disableAttachPA: false,
      loadingApEval: false,
      is_second_pa: false,
      evaluation_date: '',
      btntaskSubmit: false,
      btnCommentSubmit: false,
    }
  },
  setup() {
    const primevue = usePrimeVue();
    const store = useStore();
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    const user = computed(() => {
      return store.getters.currentUser
    });
    const toast = useToast();
    let user_id = user.value?.id;
    const t = primevue.config.StoreTranslation.t
    const countStatusImport = computed(() => {
      return store.getters.toolsObject.countStatusImport
    })
    const site = computed(() => {
      return store.getters.currentSite
    });
    const is_rte = computed(() => {
      return store.getters.isRte
    });
    return {store, id_site, toast, primevue, t, user, user_id, countStatusImport, site, is_rte};
  },
  async created() {
    this.loadingAP = true
    await this.initFilters1();
    await this.getStatusImport()
    const countInterval = setInterval(() => {
      this.getStatusImport()
    }, 30000)
    await this.getUserSiteRole()
    await this.getCurrentSites()
    await this.getUsersAP()
    await this.getCategoriesByTheme();
    this.getStatusEvaluation();
    await this.getActionPlans(1, 'init');
    this.countInterval = countInterval

  },
  mounted() {
    this.startProgress();
    this.verifUser46 = this.user?.id == 46 ? true : false
    if (this.user?.application_master) {
      this.verifSuperAdmin = true
    }
  },
  beforeUnmount() {
    this.endProgress();
    clearInterval(this.countInterval)

  },
  watch: {
    id_site() {
      this.getUserSiteRole()
    },
    user() {
      if (this.user?.role_user && this.user.role_user[0] !== 'ROLE_ADMIN') {
        this.verifAdmin = false
      }
      if (this.user?.application_master) {
        this.verifSuperAdmin = true
      }
      this.user_id = this.user?.id
      if (this.user_id == 46)
        this.verifUser46 = true
      this.getUserSiteRole()
    },
    countStatusImport() {
      if (this.countStatusImport < 1 && this.listSitesValid) {
        this.loadingAP = false
        this.getActionPlans(1, 'init');
      } else {
        this.loadingAP = true
      }
    }
  },
  methods: {
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.valueProgress + Math.floor(Math.random() * 10) + 1;
        if (newValue >= 50) {
          newValue = 50;
        }
        this.valueProgress = newValue;
      }, 100);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    initFilters1() {
      this.filters1 = {
        'title': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'start_date': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'end_date': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'revised_end_date': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'user_pilot': {value: null, matchMode: FilterMatchMode.EQUALS},
        'user_in_charge': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'rate_progress': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'priority_flag': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
      this.filters2 = {
        'title': {value: null, matchMode: FilterMatchMode.EQUALS},
        'start_date': {value: null, matchMode: FilterMatchMode.EQUALS},
        'end_date': {value: null, matchMode: FilterMatchMode.EQUALS},
        'revised_end_date': {value: null, matchMode: FilterMatchMode.EQUALS},
        'user_pilot': {value: null, matchMode: FilterMatchMode.EQUALS},
        'user_in_charge': {value: null, matchMode: FilterMatchMode.EQUALS},
        'rate_progress': {value: null, matchMode: FilterMatchMode.EQUALS},
        'priority': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
      this.filtersCompliances = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.EQUALS},
        'dated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
        'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain': {value: null, matchMode: FilterMatchMode.EQUALS},
        'subDomain': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      return this.store.dispatch(Actions.GET_CATEGORIES_TREE_FILTER, {
        'customHeader': false,
        site: this.id_site,
      }).then(data => {
        var formatSelectTree = (obj) => {
          obj.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories)
              if (element?.type === 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element?.type === 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
            } else {
              if (element?.type === 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }
            Object.assign(element, {"children": element.categories})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})
            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })
          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)
        this.categoriesTheme = tabCategoriesTheme;
        this.categoriesDomain = tabCategoriesDomain;
        this.categoriesSubDomain = tabCategoriesSubDomain;
        this.tabCategoriesTheme = tabCategoriesTheme
        this.tabCategoriesDomain = tabCategoriesDomain
        this.tabCategoriesSubDomain = tabCategoriesSubDomain
        this.categoriesThemeConst = tabCategoriesTheme
        this.categoriesDomainConst = tabCategoriesDomain
        this.categoriesSubDomainConst = tabCategoriesSubDomain
      })
    },
    filterDataTable(event) {
      this.totalItemsAP = event.filteredValue.length
      this.titleTotalNumber = this.totalItemsAP ? this.totalItemsAP + ' ' + this.t('actionsPlans') : this.totalItemsAP + ' ' + this.t('actionPlan');
      this.titleFilter = event?.filters?.title?.value
      this.startDateFilter = event?.filters?.start_date?.value
      this.endDateFilter = event?.filters?.end_date?.value
      this.revisedEndDateFilter = event?.filters?.revised_end_date?.value
      this.userPilotFilter = event?.filters?.user_pilot?.value
      this.userInChargeFilter = event?.filters?.user_in_charge?.value
      this.rateProgressFilter = event?.filters?.rate_progress?.value
      if (event?.filters?.priority_flag?.value === "red") {
        this.priorityFilter = '80'
      } else if (event?.filters?.priority_flag?.value === "green") {
        this.priorityFilter = '79'
      } else if (event?.filters?.priority_flag?.value === "yellow") {
        this.priorityFilter = '78'
      } else {
        this.priorityFilter = ''
      }
    },
    filterDataTableAttach(event) {
      this.titleFilter = event?.filters?.title?.value
      this.startDateFilter = event?.filters?.start_date?.value
      this.endDateFilter = event?.filters?.end_date?.value
      this.revisedEndDateFilter = event?.filters?.revised_end_date?.value
      this.userPilotFilter = event?.filters?.user_pilot?.value
      this.userInChargeFilter = event?.filters?.user_in_charge?.value

      this.getActionPlans(1, 'attach')
    },
    changeTheme() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      let objTheme = this.categoriesTheme.find(o => o.id == this.categoryValueTheme.id);
      if (objTheme) {
        objTheme.categories?.forEach(function (element) {
          if (element.type === 'DOMAIN') {
            tabCategoriesDomain?.push(element)
            if (element.categories?.length > 0) {
              element.categories?.forEach(elemSubDomain => {
                tabCategoriesSubDomain?.push(elemSubDomain)
              })
            }
          }
        })
      }
      this.categoriesDomain = tabCategoriesDomain;
      this.categoriesSubDomain = tabCategoriesSubDomain;
    },
    changeDomain() {
      let tabCategoriesSubDomain = []
      if (this.categoryValueDomain) {
        let objDomain = this.categoriesDomain.find(o => o.id == this.categoryValueDomain.id);
        objDomain.categories.forEach(function (element) {
          if (element.type === 'SUB_DOMAIN') {
            tabCategoriesSubDomain.push(element)
          }
        })
        this.categoryValueSubDomain = 2
        this.categoriesSubDomain = tabCategoriesSubDomain;
      }
    },
    truncate(source) {
      if (source) {
        let size
        if (screen.width <= 768) {
          size = 100
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        } else {
          size = 125
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        }
      }
    },
    truncateText(source) {
      if (source) {
        let size
        size = 60
        return source.length > size ? source.slice(0, size - 1) + " …" : source;
      }
    },
    formatDate(value) {
      if (value !== undefined) {
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } else return ' '
    },
    getActionPlans(page, type) {
      this.loadingAP = true
      this.loadingAPAttach = true
      this.actionPlanList = []

      if (this.id_site !== 'null') {
        let sites = this.listSiteByEntity?.length > 0 ? this.listSiteByEntity : [this.id_site]
        let user_id = this.user?.application_master ? [this.user_id, this.user?.application_master] : [this.user_id]
        return this.store.dispatch(Actions.GET_ACTION_PLAN_REPORT, {
          is_ap_expired: true,
          page: page,
          'customHeader': false,
          'site': sites,
          user_id: user_id,
          'is_master_admin': this.user?.application_master,
        }).then(data => {
          if (data) {
            data.forEach(element => {
              element.start_date = new Date(element.start_date)
              element.end_date = new Date(element.end_date)
              element.revised_end_date = new Date(element.revised_end_date)
              element.user_in_charge = element.user_in_charge_firstname + ' ' + element.user_in_charge_lastname
              if (element.priority == 78) element.priority_flag = 'yellow'
              else if (element.priority == 79) element.priority_flag = 'green'
              else if (element.priority == 80) element.priority_flag = 'red'
            })

            this.totalItemsAP = data.length
            this.totalItemsAPAttach = data.length
            this.titleTotalNumber = this.totalItemsAP ? this.totalItemsAP + ' ' + this.t('actionsPlans') : this.totalItemsAP + ' ' + this.t('actionPlan');
            if (type === 'all') {
              this.expandedRowsDoc = null;
              this.actionPlanList = data
            } else if (type === 'attach') {
              this.actionPlanListAttach = data
            } else {
              this.expandedRowsDoc = null;
              this.actionPlanList = data
              this.actionPlanListAttach = data
              this.actionPlanListAttachConst = data
            }
          }
          this.loadingAP = this.countStatusImport >= 1;
          this.loadingAPAttach = false
        })
      } else {
        this.loadingAPAttach = false
      }
    },
    collapseAllAP() {
      this.expandedRowsAP = null;
    },
    onPageActionPlan(page) {
      this.nbPageActionPlan = page
      this.getActionPlans(page, 'all');
    },
    onPageActionPlanAttach(page) {
      this.nbPageActionPlanAttach = page
      this.getActionPlans(page, 'attach');
    },
    openModalAP(second_ap) {
      this.PADialog = true
      this.planAction = {}
      this.valueSubDomain = ''
      this.priority = ''
      this.start_date = ''
      this.end_date = ''
      this.revised_end_date = ''
      this.apPilot = ''
      this.responsibleAP = ''
      this.rateProgress = {id: 1, value: 0, label: '0%',}
      this.typeProgress = {id: 1, value: 'Par le chargé du plan d\'actions'}
      this.verification_date = ''
      if (second_ap)
        this.is_second_pa = true
    },
    selectNode(selected) {
      if (selected.children?.length) {
        setTimeout(() => {
          this.valueSubDomain = {[selected.id]: false}
        }, 100);
      }
    },
    hideDialogPA() {
      this.PADialog = false
      this.submittedPA = false
    },
    savePA() {
      let actionPlan = this.planAction
      this.submittedPA = true
      if (moment(this.start_date).isValid()) {
        actionPlan.start_date = moment(this.start_date).format()
      }
      if (moment(this.end_date).isValid()) {
        actionPlan.end_date = moment(this.end_date).format()
      }
      if (moment(this.revised_end_date).isValid()) {
        actionPlan.revised_end_date = moment(this.revised_end_date).format()
      }
      if (this.responsibleAP) {
        actionPlan.user_in_charge = 'api/users/' + this.responsibleAP.id
      }
      if (this.apPilot) {
        actionPlan.user_pilot = 'api/users/' + this.apPilot.id
      }
      actionPlan.site = 'api/sites/' + this.id_site
      if (this.valueSubDomain) {
        actionPlan.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
      }
      if (this.priority && this.priority.id !== 0) {
        actionPlan.priority = this.priority.value
      }
      if (this.rateProgress) {
        actionPlan.rate_progress = this.rateProgress.value
      }
      if (this.typeProgress) {
        actionPlan.type_progression = this.typeProgress.value
      }
      actionPlan.created_by = 'api/users/' + this.user_id
      if (this.idEvaluation) {
        actionPlan.evaluation = ['api/evaluations/' + this.idEvaluation]
      }

      if (this.planAction.title && this.planAction.start_date && this.planAction.end_date && this.planAction.revised_end_date && this.responsibleAP) {
        this.store.dispatch(Actions.ADD_ACTION_PLAN, actionPlan).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('actionPlanHasSuccessfullyAdded'),
            life: 3000
          });

          if (this.is_second_pa && this.idPA)
            this.getActionPlanRef(this.idPA)

          this.getActionPlans(1, 'all');
          if (this.idEvaluation) {
            this.getActionPlansEval(this.nbPageActionPlanEval)
          }
          this.PADialog = false
          this.submittedPA = false
        })
      }
    },

    modalEditPA(data) {
      this.site_id_in_modal = data?.site?.id
      this.getUserSiteRole()
      this.planAction = {}
      this.valueSubDomain = ''
      this.priority = ''
      this.start_date = ''
      this.end_date = ''
      this.revised_end_date = ''
      this.apPilot = ''
      this.responsibleAP = ''
      this.verification_date = ''
      this.PAEditDialog = true
      this.loadingComments = true
      this.loadingTasks = true
      this.idPA = data.id
      this.getActionPlanRef(this.idPA)
    },
    getActionPlan(data) {
      this.planAction = data
      this.loadingTasks = true
      this.loadingComments = true
      this.actionPlanDetails = data
      this.totalItemsCompliancePA = data?.evaluation?.length
      this.totalItemsTasks = data?.action_plan_tasks?.length
      this.totalItemsComments = data?.action_plan_comments?.length
      this.loadingTasks = false
      this.loadingComments = false
      this.titleAP = data.title
      if (data.sub_domain) {
        this.valueSubDomain = {[data.sub_domain.id]: true}
      }
      this.priority = this.valuesPriorities.find(item => item.value === data.priority)
      if (moment(data.start_date).isValid()) {
        this.start_date = new Date(moment(data.start_date).format('yy-MM-DD'))

      }
      if (moment(data.end_date).isValid()) {
        this.end_date = new Date(moment(data.end_date).format('yy-MM-DD'))

      }
      if (moment(data.revised_end_date).isValid()) {
        this.revised_end_date = new Date(moment(data.revised_end_date).format('yy-MM-DD'))

      }
      if (data.user_pilot) {
        this.apPilot = this.listUsers.find(item => item.id == data.user_pilot.id)
      }
      if (data.user_in_charge) {
        this.responsibleAP = this.listUsers.find(item => item.id == data.user_in_charge.id)
      }
      if (data.type_progression) {
        this.typeProgress = this.listTypeProgression.find(item => (item.value).toUpperCase() == (data.type_progression).toUpperCase())
      } else {
        this.typeProgress = {id: 1, value: 'Par le chargé du plan d\'actions'}
      }
      if (data.rate_progress) {
        this.rateProgress = this.listRateProgression.find(item => item.value == data.rate_progress)
      } else {
        this.rateProgress = {id: 1, value: 0, label: '0%'};
      }
      if (data.verification_date !== undefined) {
        if (moment(data.verification_date).isValid()) {
          this.verification_date = new Date(moment(data.verification_date).format('yy-MM-DD'))
        }
      }
      if (data.verifier_user) {
        this.verifier_user = this.listUsers.find(item => item.id == data.verifier_user.id)
      }
    },
    savePAEdit(editWithouEvalCommTask = 0) {
      this.submittedPA = true
      if (moment(this.start_date).isValid()) {
        this.planAction.start_date = moment(this.start_date).format()
      }
      if (moment(this.end_date).isValid()) {
        this.planAction.end_date = moment(this.end_date).format()
      }
      if (moment(this.revised_end_date).isValid()) {
        this.planAction.revised_end_date = moment(this.revised_end_date).format()
      }
      if (moment(this.verification_date).isValid()) {
        this.planAction.verification_date = moment(this.verification_date).format()
      }
      if (this.responsibleAP) {
        this.planAction.user_in_charge = 'api/users/' + this.responsibleAP.id
      }
      if (this.apPilot) {
        this.planAction.user_pilot = 'api/users/' + this.apPilot.id
      }
      if (this.verifier_user) {
        this.planAction.verifier_user = 'api/users/' + this.verifier_user.id
      }
      this.planAction.site = 'api/sites/' + this.id_site
      if (this.valueSubDomain) {
        this.planAction.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
      }
      if (this.priority) {
        this.planAction.priority = this.priority.value
      }
      if (this.rateProgress) {
        this.planAction.rate_progress = this.rateProgress.value
      }
      if (this.typeProgress) {
        this.planAction.type_progression = this.typeProgress.value
      }
      if (!editWithouEvalCommTask) {
        if (this.evaluationsAP.length > 0) {
          this.planAction.evaluation = []
          this.evaluationsAP.forEach(element => {
            this.planAction.evaluation.push('api/evaluations/' + element.id)
          })
        }
      } else {
        delete this.planAction.evaluation;
        delete this.planAction.action_plan_tasks;
        delete this.planAction.action_plan_comments;
      }
      this.planAction.updated_by = 'api/users/' + this.user_id
      if (this.planAction.created_by) {
        this.planAction.created_by = 'api/users/' + this.planAction.created_by.id
      }
      delete this.planAction.site;
      if (this.planAction.title && this.start_date && this.end_date && this.revised_end_date && this.responsibleAP) {
        this.store.dispatch(Actions.PUT_ACTION_PLAN, this.planAction).then(() => {
          this.submittedPA = false
          this.toast.add({
            severity: 'success',
            detail: this.t('actionPlanHasSuccessfullyModified'),
            life: 3000
          });
          if (this.PAEvalEditDialog) {
            this.PAEvalEditDialog = false
            this.getActionPlanRef(this.idPA)
            this.getActionPlansEval(this.nbPageActionPlanEval)
          } else {
            this.PAEditDialog = false
            this.onPageActionPlan(this.nbPageActionPlan)
          }
        })
      }
    },
    openModalTasks(data) {
      this.tasksDialog = true
      this.task = {}
      this.start_date_task = ''
      this.end_date_task = ''
      this.rateProgress_task = {id: 1, value: 0, label: '0%',}
      this.responsibleTask = ''
      if (data) {
        this.getTaskDetails(data)
      }
    },
    hideDialogTask() {
      this.tasksDialog = false
    },
    saveTask() {
      this.submittedTasks = true
      if (this.start_date_task) {
        if (moment(this.start_date_task).isValid())
          this.task.start_date = moment(this.start_date_task).format()
      }
      if (this.end_date_task) {
        if (moment(this.end_date_task).isValid())
          this.task.end_date = moment(this.end_date_task).format()
      }
      if (this.responsibleTask) {
        this.task.user_in_charge = 'api/users/' + this.responsibleTask.id
      }
      if (this.rateProgress_task) {
        this.task.rate_progress = this.rateProgress_task.value
      }
      this.task.action_plan = 'api/action_plans/' + this.idPA
      if (this.task.title && this.start_date_task && this.end_date_task) {
        if (this.task.id) {
          if (this.task.created_by) {
            this.task.created_by = 'api/users/' + this.task.created_by.id
          }
          this.task.updated_by = 'api/users/' + this.user_id
          this.btntaskSubmit = true
          this.store.dispatch(Actions.PUT_TASKS_AP, this.task).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('taskHasSuccessfullyModified'),
              life: 3000
            });
            this.submittedTasks = false
            this.tasksDialog = false
            this.getActionPlanRef(this.idPA)
            this.btntaskSubmit = false
          })
        } else {
          this.task.created_by = 'api/users/' + this.user_id
          this.btntaskSubmit = true
          this.store.dispatch(Actions.ADD_TASK_AP, this.task).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('taskHasSuccessfullyAdded'),
              life: 3000
            });
            this.submittedTasks = false
            this.tasksDialog = false
            this.getActionPlanRef(this.idPA)
            this.btntaskSubmit = false
          })
        }
      }
    },
    getTaskDetails(data) {
      this.task = data
      if (data.start_date) {
        this.start_date_task = moment(data.start_date).format('DD/MM/yy')
      }
      if (data.end_date) {
        this.end_date_task = moment(data.end_date).format('DD/MM/yy')
      }
      if (data.user_in_charge) {
        this.responsibleTask = this.listUsers.find(item => item.id == data.user_in_charge.id)
      }
      if (data.rate_progress) {
        this.rateProgress_task = this.listRateProgression.find(item => item.value == data.rate_progress)
      } else {
        this.rateProgress_task = {id: 1, value: 0, label: '0%',}
      }
    },
    hideDialogEditPA() {
      this.PAEditDialog = false
    },
    openModalComments(data) {
      this.commentAP = {}
      this.commentsDialog = true
      if (data) {
        this.commentAP.id = data.id
        this.commentAP.comment = data.comment
      }
    },
    hideDialogComment() {
      this.commentsDialog = false
    },
    saveComment() {
      this.submittedComments = true
      this.commentAP.updated_by = '/api/users/' + this.user.id
      this.commentAP.action_plan = 'api/action_plans/' + this.idPA
      if (this.commentAP.id) {
        this.btnCommentSubmit = true
        this.store.dispatch(Actions.PUT_COMMENT_AP, this.commentAP).then(() => {
          this.commentsDialog = false
          this.toast.add({
            severity: 'success',
            detail: this.t('commentHasSuccessfullyModfied'),
            life: 3000
          });
          this.getActionPlanRef(this.idPA)
          this.submittedComments = false
          this.btnCommentSubmit = false
        })
      } else {
        this.commentAP.created_by = '/api/users/' + this.user.id
        if (this.commentAP.comment) {
          this.btnCommentSubmit = true
          this.store.dispatch(Actions.ADD_COMMENT_AP, this.commentAP).then(() => {
            this.commentsDialog = false
            this.toast.add({
              severity: 'success',
              detail: this.t('commentHasSuccessfullyAdded'),
              life: 3000
            });
            this.getActionPlanRef(this.idPA)
            this.submittedComments = false
            this.btnCommentSubmit = false
          })
        }
      }
    },
    getActionPlanRef(id) {
      this.loadingTasks = true
      this.loadingComments = true
      this.loadingCompliancesPA = true
      this.store.dispatch(Actions.GET_ACTION_PLAN_BY_ID, {'customHeader': false, idAP: id}).then(data => {
        this.getActionPlan(data)
        this.planAction.action_plan_tasks = data.action_plan_tasks
        this.planAction.action_plan_comments = data.action_plan_comments
        let objectPA = this.actionPlanList.find(item => item.id == id)
        objectPA.evaluation = data.evaluation
        this.evaluationsAP = data.evaluation
        this.totalItemsCompliancePA = data.evaluation.length
        this.totalItemsTasks = data.action_plan_tasks.length
        this.totalItemsComments = data.action_plan_comments.length
        this.loadingTasks = false
        this.loadingComments = false
        this.loadingCompliancesPA = false
      })
    },
    openModalCompliance() {
      this.complianceDialog = true
      this.getDocuments(1, this.nbItems)
    },
    hideDialogCompliance() {
      this.complianceDialog = false
    },
    saveCompliances() {
      this.btnAPSubmit = true
      this.planAction.id = this.idPA
      let compliancesSelected = []
      Object.entries(this.selectedCompliances).forEach(element => {
        element[1].forEach(item => {
          compliancesSelected.push('api/evaluations/' + item.evaluations[0].id)
        })
      })
      let objectPA = this.actionPlanList.find(item => item.id == this.idPA)
      objectPA.evaluation.forEach(element => {
        compliancesSelected.push('api/evaluations/' + element.id)
      })
      this.planAction.updated_by = 'api/users/' + this.user_id
      this.store.dispatch(Actions.PUT_ACTION_PLAN, {id: this.idPA, evaluation: compliancesSelected}).then(() => {
        this.selectedCompliances = []
        this.complianceDialog = false
        this.getActionPlanRef(this.idPA)
        this.btnAPSubmit = false
      })
    },
    getDocuments(page = 1, nbItems) {
      this.loadingDoc = true;
      this.documents = []
      this.nbItems = nbItems
      let idTheme = ''
      let idDomain = ''
      let idSubDomain = ''
      if (this.categoryValueTheme && !this.categoryValueSubDomain) {
        idTheme = this.categoryValueTheme.id
      }
      if (this.categoryValueDomain) {
        idDomain = this.categoryValueDomain.id
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      }
      this.categoriesThemeDocument = []
      this.categoriesDomainDocument = []
      this.categoriesSubDomainDocument = []
      this.store.dispatch(Actions.GET_DOCUMENTS_STAT, {
        'idSite': this.id_site,
        'page': page,
        'limit': this.nbItems,
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'customHeader': true,
      }).then(data => {
        if (data) {
          this.loadingDoc = false;
          this.expandedRowsDoc = null;
          this.totalItemsDoc = data['hydra:member'][1]
          data['hydra:member'][0].forEach(element => {
            let objTheme = this.categoriesThemeConst.find(o => o.id == element.theme_id);
            let objDomain = this.categoriesDomainConst.find(o => o.id == element.domain_id);
            let objSubDomain = this.categoriesSubDomainConst.find(o => o.id == element.sub_domain_id);
            this.categoriesThemeDocument.push(element.theme_id)
            this.categoriesDomainDocument.push(element.domain_id)
            this.categoriesSubDomainDocument.push(element.sub_domain_id)
            if (objTheme) {
              element['theme_id'] = objTheme.name
            } else {
              element['theme_id'] = objTheme
            }
            if (objDomain) {
              element['domain_id'] = objDomain.name

            } else {
              element['domain_id'] = objDomain
            }
            if (objSubDomain) {
              element['sub_domain_id'] = objSubDomain.name

            } else {
              element['sub_domain_id'] = objSubDomain
            }
          })
          this.documents = data['hydra:member'][0]
        }
      }).catch(() => {
      })
    },
    onPageDoc(event) {
      this.nbItems = event.rows
      this.nbPageDocument = event.page + 1
      this.getDocuments(event.page + 1, this.nbItems);
    },
    onRowExpandDoc(documentId, page = 1) {
      this.loadingSection[documentId] = true;
      let id_Doc = documentId;
      let objectDocument = this.documents.find(element => element.id == id_Doc)
      this.store.dispatch(Actions.GET_SECTIONS_STAT, {
        'document': id_Doc,
        'site': this.id_site,
        'withStat': 1,
        'page': page,
        'customHeader': true,
        'limit': 10,
      }).then(data => {
        this.loadingSection[documentId] = false;
        this.sections = data['hydra:member'][0]
        this.totalItemsSect[documentId] = data['hydra:member'][1]
        this.sections = this.sections.map((data) => ({
          ...data,
          id_document: documentId,
        }));
        Object.assign(objectDocument, {'sections': this.sections, 'id_document': documentId})
      }).catch(() => {
      })
    },
    onRowExpandSection(dataSection, page = 1) {
      let documentsTab = []
      this.loadingCompliance[dataSection.id] = true;
      let id_sect = dataSection.id
      let id_doc = dataSection.id_document
      return this.store.dispatch(Actions.GET_COMPLIANCES, {
        'section': id_sect,
        'evaluationSite': this.id_site,
        'page': page,
        'customHeader': true,
        'limit': 10,
        'evaluationDocument': id_doc,
      }).then(data => {
        let objectPA = this.actionPlanList.find(item => item.id == this.idPA)
        this.loadingCompliance[dataSection.id] = false;
        this.compliances = data['hydra:member'].map((data) => ({
          ...data,
          titleSection: dataSection.title,
          idSection: dataSection.id,
          id_document: dataSection.id_document,
        }));
        if (objectPA.evaluation) {
          objectPA.evaluation.forEach(element => {
            this.compliances = this.compliances.filter(item => item.id != element.compliance.id)
          })
        }
        documentsTab = this.documents
        documentsTab.find((item, index) => {
          if (item.id == id_doc) {
            documentsTab[index].sections.find((element, index2) => {
              if (element.id == id_sect) {
                Object.assign(documentsTab[index].sections[index2], {
                  'compliances': this.compliances,
                  'id_section': id_sect,
                  'id_document': id_doc
                })
              }
            })
          }
        })
        this.documents = documentsTab
        this.totalItemsCompliance[dataSection.id] = data['hydra:totalItems']
      }).catch(() => {
      })
    },
    collapseAll() {
      this.expandedRowsDoc = null;
    },
    onPageSec(documentId, page) {
      this.nbpageSection = page
      this.onRowExpandDoc(documentId, page);
    },
    onpageCompliance(dataSection, event) {
      this.nbPageCompliance = event.page + 1
      this.onRowExpandSection(dataSection, this.nbPageCompliance);
    },
    rowClass(rowData) {
      return rowData.compliances_count > 0 ? "" : "no-expander";
    },
    dialogEvaluation(dataComp) {
      this.titleDialogEvaluation = dataComp.compliance.section.title + '\n' + dataComp.compliance.title
      this.evaluationDialog = true
      this.idEvaluation = dataComp.id
      this.evaluation.id = dataComp.id
      if (this.valuesEvaluations) {
        this.evaluationStatus = this.valuesEvaluations.find(element =>
            element.id == dataComp.status.id
        )
      }
      this.evaluation_date = ''
      if (dataComp.evaluation_date) {
        this.evaluation_date = new Date(moment(dataComp.evaluation_date).format('yy-MM-DD'))
      }
      this.evaluation.annotation = dataComp.annotation;
      this.evaluation.comment = dataComp.comment;
      this.getHistoricEvaluation(dataComp.id);
      this.onPageActionPlanEval(1)
    },
    getHistoricEvaluation(idEval) {
      this.store.dispatch(Actions.HISTORIC_EVALUATION, {
        idEvaluation: idEval,
        'page': this.pageHistEval,
        'customHeader': true,
        'limit': 10,
      }).then(data => {
        if (data) {
          this.historicEvaluation = data['hydra:member']
          this.totalItemsHistEval = data['hydra:totalItems']
        }
      })
    },
    onPageHistEval(nbPage) {
      this.nbPageHistoric = nbPage
      this.getHistoricEvaluation(nbPage, this.idEvaluation)
    },
    getActionPlansEval(page) {
      this.loadingApEval = true
      this.store.dispatch(Actions.GET_ACTION_PLAN, {
        page: page,
        'customHeader': true,
        'evaluation': this.idEvaluation
      }).then(data => {
        this.loadingApEval = false
        this.actionPlanListEval = data['hydra:member']
        this.totalItemsAPEval = data['hydra:totalItems']
      })
    },
    onPageActionPlanEval(page) {
      this.nbPageActionPlanEval = page
      this.getActionPlansEval(page);
    },
    getStatusEvaluation() {
      this.store.dispatch(Actions.GET_STATUS_EVALUATION, {'customHeader': false}).then(data => {
        this.valuesEvaluations = data
        this.valuesEvaluations = this.valuesEvaluations.filter(item => item.id === 47 || item.id === 299 || item.id === 48 || item.id === 300 || item.id === 49 || item.id === 50 || item.id === 51 || item.id === 52 || item.id === 310 || item.id === 311 || item.id === 312)
      }).catch(() => {
      })
    },
    saveEvaluation() {
      this.submitted = true
      if (this.evaluationStatus) {
        if (this.evaluation_date === moment().format('DD/MM/yy')) {
          this.evaluation.evaluation_date = moment().format()
        } else if (moment(this.evaluation_date).isValid()) {
          this.evaluation.evaluation_date = moment(this.evaluation_date).format()
        }
        this.evaluation['status'] = '/api/evaluation_statuses/' + this.evaluationStatus.id
        this.evaluation.user = 'api/users/' + this.user_id
        this.store.dispatch(Actions.PUT_EVALUATION, this.evaluation).then(async () => {
          this.toast.add({
            severity: 'success',
            detail: this.t('evaluationHasSuccessfullyModified'),
            life: 3000
          });
          this.evaluationDialog = false
          this.getActionPlanRef(this.idPA)
          this.submitted = false
        }).catch(() => {
        })
      }
    },
    deleteCompliance(data) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_EVALUATION_AP, {idPA: this.idPA, idEval: data.id}).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('ComplianceHasSuccessfullyDeleted'),
              life: 3000
            });
            this.getActionPlanRef(this.idPA)
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    hideDialogEval() {
      this.evaluationDialog = false
    },
    modalEditPAEval(data) {
      this.PAEvalEditDialog = true
      this.planAction = {}
      this.valueSubDomain = ''
      this.priority = ''
      this.start_date = ''
      this.end_date = ''
      this.revised_end_date = ''
      this.apPilot = ''
      this.responsibleAP = ''
      this.verification_date = ''
      this.PAEditDialog = true
      this.loadingComments = true
      this.loadingTasks = true
      this.getActionPlan(data)
      this.is_second_pa = true
    },
    hideDialogEditPAEval() {
      this.PAEvalEditDialog = false
      this.is_second_pa = false
    },
    modalAttachAP() {
      this.attachPADialog = true
      this.actionPlanListAttach = this.actionPlanListAttachConst.filter((elem) => {
        return this.actionPlanListEval.every((ele) => {
          return ele.id !== elem.id;
        });
      })
    },
    hideDialogAttachPA() {
      this.attachPADialog = false
    },
    saveAttachPA() {
      this.disableAttachPA = true
      let selectedAPAttach = []
      this.selectedAPAttach?.forEach(element => {
        selectedAPAttach.push('api/action_plans/' + element.id)
      })
      this.store.dispatch(Actions.PUT_EVALUATION, {
        id: this.idEvaluation,
        action_plans: selectedAPAttach
      }).then(() => {
        this.getActionPlansEval(1)
        this.attachPADialog = false
        this.toast.add({
          severity: 'success',
          detail: this.t('actionPlanHasSuccessfullyAdded'),
          life: 3000
        });
        this.disableAttachPA = false
        this.selectedAPAttach = []
      })
    },
    getUsersAP() {
      this.store.dispatch(Actions.GET_USERS_AP, {site_id: this.id_site, 'customHeader': false,}).then((data) => {
        this.listUsers = data.filter(item => item.hide_in_pa !== 1)
      })
    },
    changeEndDate(event) {
      this.revised_end_date = event
    },
    chnageStatus() {
      this.evaluation_date = moment().format('DD/MM/yy');
    },
    filterDataTableCompliance(event) {
      this.textAdmin = event?.filters?.titleDescription?.value
      this.categoryValueTheme = event?.filters?.theme?.value
      if (!event?.filters?.theme?.value && !event?.filters?.domain?.value && !event?.filters?.subDomain?.value) {
        this.categoriesTheme = this.tabCategoriesTheme
        this.categoriesDomain = this.tabCategoriesDomain
        this.categoriesSubDomain = this.tabCategoriesSubDomain
      }
      if (event?.filters?.theme?.value) {
        this.changeTheme()
      } else if (!event?.filters?.domain?.value) {
        this.getCategoriesByTheme()
      }
      if (this.categoriesDomain.length > 0) {
        if (event?.filters?.domain?.value !== this.categoryValueDomain) {
          this.categoryValueDomain = event.filters.domain.value
          this.changeDomain()
          if ((this.categoryValueSubDomain === 2 && !event?.filters?.subDomain?.value) || (this.categoryValueSubDomain === 2 && event?.filters?.subDomain?.value)) {
            event.filters.subDomain.value = null
          }
        }
        this.categoryValueSubDomain = event?.filters?.subDomain?.value
      } else {
        event.filters.domain.value = null
        event.filters.subDomain.value = null
        this.categoryValueDomain = ''
        this.categoryValueSubDomain = null
      }
      this.getDocuments(1, this.nbItems)

    },
    deletePA(actionPlan) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_AP, actionPlan.id).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('actionPlanHasSuccessfullyDeleted'),
              life: 3000
            });
            this.getActionPlans(1, 'init');
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    deleteTask(task) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_AP_TASK, task.id).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('taskAPHasSuccessfullyDeleted'),
              life: 3000
            });
            this.getActionPlanRef(this.idPA)
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    deleteComment(comment) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_AP_COMMENT, comment.id).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('commentAPHasSuccessfullyDeleted'),
              life: 3000
            });
            this.getActionPlanRef(this.idPA)
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    deleteAPEval(data) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_EVALUATION_AP, {
            idPA: data.id,
            idEval: this.idEvaluation
          }).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('ComplianceHasSuccessfullyDeleted'),
              life: 3000
            });
            this.getActionPlansEval(1)
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },

    getStatusImport() {
      return this.store.dispatch(Actions.GET_STATUS_IMPORT, {
        'site_id': this.id_site,
        'model': 'App\\Command\\ImportPaRteCommand',
        'customHeader': true
      }).then(() => {
      })
    },


    exportAP() {
      let user = '/api/users/' + this.user.id
      let rate_progress_start
      if (this.rateProgressFilter) {
        rate_progress_start = this.rateProgressFilter[0]
      }
      let rate_progress_end
      if (this.rateProgressFilter) {
        rate_progress_end = this.rateProgressFilter[1]
      }

      let startDate_range_start
      let startDate_range_end
      if (this.startDateFilter) {
        startDate_range_start = moment(this.startDateFilter[0]).format('yy-MM-DD')

        startDate_range_end = this.startDateFilter[1] ? moment(this.startDateFilter[1]).format('yy-MM-DD') : this.addEndOfDayTime(moment(this.startDateFilter[0]).format('yy-MM-DD'))
      }

      let endDate_range_start
      let endDate_range_end
      if (this.endDateFilter) {
        endDate_range_start = moment(this.endDateFilter[0]).format('yy-MM-DD')
        endDate_range_end = this.endDateFilter[1] ? moment(this.endDateFilter[1]).format('yy-MM-DD') : this.addEndOfDayTime(moment(this.endDateFilter[0]).format('yy-MM-DD'))
      }
      let revisedEndDatee_range_start
      let revisedEndDatee_range_end
      if (this.revisedEndDateFilter) {
        revisedEndDatee_range_start = moment(this.revisedEndDateFilter[0]).format('yy-MM-DD')
        revisedEndDatee_range_end = this.revisedEndDateFilter[1] ? moment(this.revisedEndDateFilter[1]).format('yy-MM-DD') : this.addEndOfDayTime(moment(this.revisedEndDateFilter[0]).format('yy-MM-DD'))
      }

      let sites = this.listSiteByEntity.length > 0 ? this.listSiteByEntity : [this.id_site]
      this.btnExport = true
      this.store.dispatch(Actions.EXPORTS_POST, {
        user: user,
        model: "actionPlanEchusReportExport",
        params: {
          site_id: this.id_site,
          'title': this.titleFilter,
          'start_date_range_start': startDate_range_start,
          'start_date_range_end': startDate_range_end,
          'end_date_range_start': endDate_range_start,
          'end_date_range_end': endDate_range_end,
          'revised_end_date_range_start': revisedEndDatee_range_start,
          'revised_end_date_range_end': revisedEndDatee_range_end,
          'user_in_charge': this.userInChargeFilter,
          'priority': this.priorityFilter,
          'rate_progress_start': rate_progress_start,
          'rate_progress_end': rate_progress_end,
          'site_id_to_export': sites,
          'sites': sites,
          'is_rte': this.store.getters.isRte,
          'userId': this.user.id,
          'is_master_admin': this.user?.application_master,
          is_ap_expired: true,
        },
        site_id: parseInt(this.id_site),
      }).then(() => {
        this.btnExport = false
        this.toast.add({
          severity: 'success',
          detail: this.t('exportWasSuccessfullySent'),
          life: 8000
        });
      })
    },
    backToRapport() {
      this.$router.push('/rapport');
    },

    getCurrentSites() {
      this.listSiteByEntity = []
      let exist = false
      let objectCurrentSite = []
      this.treeSelectNodes = []
      return this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        this.listSitesValid = true
        var formatSelectTree = (obj) => {
          obj?.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
          });
          return obj;
        };
        this.treeSelectNodes = formatSelectTree(data);

        function getObjectCurrentSite(arr, id_site) {
          arr?.forEach((obj) => {
            if (obj.childrens && !exist) {
              getObjectCurrentSite(obj.childrens, id_site, obj);
            }
            if (obj.childrens && obj.id == id_site) {
              exist = true
              objectCurrentSite = obj
              return objectCurrentSite
            }

          });
          return objectCurrentSite
        }

        objectCurrentSite = getObjectCurrentSite(this.treeSelectNodes, this.id_site)
        const getSitesTree = (objectCurrentSite) => {
          if (objectCurrentSite.childrens) {
            objectCurrentSite.childrens.forEach(element => {
              if (element.entity_type === 'site') {
                this.listSiteByEntity.push(element.id);
              } else {
                getSitesTree(element);
              }
            });
          }
        };
        getSitesTree(objectCurrentSite);
      })
    },
    getUserSiteRole() {
      this.store.dispatch(Actions.GET_ROLE_USER_SITE, {user_id: this.user_id, site_id: this.id_site}).then((data) => {
        this.role_user_site = data ? data.user_role : data
      })
    },
    handleFilterDateApplied(event) {
      if (event.start_date?.value) {
        if (!event.start_date?.value[1]) {
          event.start_date.value = [
            event.start_date?.value[0],
            this.addEndOfDayTime(moment(event.start_date?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
      if (event.end_date?.value) {
        if (!event.end_date?.value[1]) {
          event.end_date.value = [
            event.end_date?.value[0],
            this.addEndOfDayTime(moment(event.end_date?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
      if (event.revised_end_date?.value) {
        if (!event.revised_end_date?.value[1]) {
          event.revised_end_date.value = [
            event.revised_end_date?.value[0],
            this.addEndOfDayTime(moment(event.revised_end_date?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
    },
    addEndOfDayTime(dateString) {
      const endDate = new Date(dateString);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      return endDate;

    },


    replaceAllColorsWithWhite(html) {
      if (!html) return '';
      return html.replace(/color: rgb\(\d{1,3}, \d{1,3}, \d{1,3}\);/g, 'color: white;');
    },

  }
}
</script>
<style scoped lang="scss">
::v-deep(.p-progressbar) {
  height: .5rem;
}



</style>

